import { defineMessage } from '@formatjs/intl';
import { CRMFile } from '../types/CRMFile';
import { i18nMessage } from '../types/i18nMessage';

// Determines if the document loaded in Office is valid by comparing the properties in the OOXML with
// those gathered from a call to get the file
export function validateFile(file: CRMFile, userId: number, timestamp: string): i18nMessage {
	if (file.reservedByProConId !== userId) {
		return defineMessage({
			id: 'fileNotReservedByYou',
			defaultMessage: 'This file is not reserved by you for editing.'
		});
	}

	if (file.version !== timestamp) {
		return defineMessage({
			id: 'fileOutOfSync',
			defaultMessage:
				'This is not the latest version of the file. Close this file and download the latest version.'
		});
	}

	return null;
}
