import React from 'react';
import { ActionButton, ButtonType, IStackTokens, Stack } from '@fluentui/react';
import { FormattedMessage } from 'react-intl';

type props = {
	setPage: (page: string) => void;
};

const stackTokens: IStackTokens = {
	childrenGap: 0,
	padding: 'm'
};

const VersionedFile: React.FunctionComponent<props> = ({ setPage }: props) => {
	return (
		<>
			<Stack grow verticalFill tokens={stackTokens} data-testid='versioned-file-stack'>
				<Stack.Item>
					<ActionButton
						className='ms-welcome__action'
						buttonType={ButtonType.hero}
						iconProps={{ iconName: 'Info' }}
						onClick={() => {
							setPage('FILE_DETAILS');
						}}
					>
						<FormattedMessage id='viewFileDetails' defaultMessage='View File Details' />
					</ActionButton>
				</Stack.Item>
				<Stack.Item>
					<ActionButton
						className='ms-welcome__action'
						buttonType={ButtonType.hero}
						iconProps={{ iconName: 'Save' }}
						onClick={() => {
							setPage('SAVE_NEW_VERSION');
						}}
					>
						<FormattedMessage id='saveAsNewVersion' defaultMessage='Replace File with New Version' />
					</ActionButton>
				</Stack.Item>
				<Stack.Item>
					<ActionButton
						className='ms-welcome__action'
						buttonType={ButtonType.hero}
						iconProps={{ iconName: 'Unlock' }}
						onClick={() => {
							setPage('RELEASE_RESERVATION');
						}}
					>
						<FormattedMessage id='clearReservation' defaultMessage='Clear Reservation' />
					</ActionButton>
				</Stack.Item>
			</Stack>
		</>
	);
};

export default VersionedFile;
