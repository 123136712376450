import React, { useState } from 'react';
import { MessageBarType, ProgressIndicator } from '@fluentui/react';
import { defineMessage, useIntl } from 'react-intl';
import { i18nMessage } from '../types/i18nMessage';
import Message from './Message';

export interface ILoadingProps {
	label?: i18nMessage;
	displayExcelCellEditWarning?: boolean;
}

// eslint-disable-next-line react/prop-types
const Loading: React.FunctionComponent<ILoadingProps> = ({ label, displayExcelCellEditWarning }) => {
	const intl = useIntl();
	const [renderExcelWarning, setRenderExcelWarning] = useState(false);

	if (!label) {
		label = defineMessage({ id: 'loading', defaultMessage: 'Loading' });
	}

	// Only want to show the warning after 5 seconds to prevent a flicker
	// and to give it a chance to load before presenting the message
	if (displayExcelCellEditWarning) {
		setTimeout(() => {
			setRenderExcelWarning(true);
		}, 5000);
	}

	return (
		<>
			<ProgressIndicator label={intl.formatMessage(label)} />
			{renderExcelWarning && (
				<Message
					message={defineMessage({
						id: 'ensureYouAreNotInCellEditMode',
						defaultMessage: 'Please ensure you are not in cell-editing mode.'
					})}
					messageBarType={MessageBarType.info}
				/>
			)}
		</>
	);
};

export default Loading;
