import { MessageBarType } from '@fluentui/react';
import React, { useContext, useEffect, useState } from 'react';
import { validateFile } from '../../helpers/FileValidator';
import Loading from '../Loading';
import Login from './Login';
import Message from '../Message';
import VersionedFile from './VersionedFile';
import NonVersionedFile from './NonVersionedFile';
import { CustomPropertiesContext } from '../../../context/CustomPropertiesContext';
import useFile from '../../hooks/useFile';
import useUser from '../../hooks/useUser';
import { i18nMessage } from '../../types/i18nMessage';

type props = {
	setPage: (page: string) => void;
};

const Home: React.FunctionComponent<props> = ({ setPage }: props) => {
	const { timestamp } = useContext(CustomPropertiesContext);
	const { file, fileErrorToken, isLoadingFileDetails } = useFile();
	const { user, userErrorToken, isLoadingUserDetails } = useUser();
	const [fileValidationErrorToken, setFileValidationErrorToken] = useState<i18nMessage>();

	useEffect(() => {
		// The file or user calls are still resolving or there is an error
		if (isLoadingUserDetails || isLoadingFileDetails || fileErrorToken || userErrorToken) {
			return;
		}

		const fileValidationMessage = validateFile(file, user.buyerProConId, timestamp);

		if (fileValidationMessage) {
			setFileValidationErrorToken(fileValidationMessage);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isLoadingFileDetails, isLoadingUserDetails, fileErrorToken, userErrorToken]);

	// Still reading custom properties or awaiting response from the server
	if (isLoadingFileDetails || isLoadingUserDetails) {
		return <Loading />;
	}

	if (userErrorToken) {
		return <Login />;
	}

	if (fileValidationErrorToken || fileErrorToken) {
		return (
			<Message
				messageBarType={MessageBarType.error}
				message={fileErrorToken ? fileErrorToken : fileValidationErrorToken}
			/>
		);
	}

	if (file.isVersionTrackingEnabled) {
		return <VersionedFile setPage={setPage} />;
	} else {
		return <NonVersionedFile setPage={setPage} />;
	}
};

export default Home;
