import { MessageBarType } from '@fluentui/react';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { communicationState } from '../../../enums/CRMStateProperties';
import Loading from '../Loading';
import Message from '../Message';
import useCommunication from '../../hooks/useCommunication';

type props = {
	fileParentId: number;
};

const CommunicationDetails: React.FunctionComponent<props> = ({ fileParentId }: props) => {
	const { isLoadingCommunicationDetails, communication, communicationErrorToken } = useCommunication(fileParentId);

	if (isLoadingCommunicationDetails) {
		return <Loading />;
	}

	if (communicationErrorToken) {
		return <Message messageBarType={MessageBarType.error} message={communicationErrorToken} />;
	}

	return (
		<>
			<h2 className='ms-fontWeight-light file-details-h2'>
				<FormattedMessage id='communicationDetails' defaultMessage='Communication Details' />
			</h2>
			<label className='ms-fontWeight-bold file-details-label'>
				<FormattedMessage id='title' defaultMessage='Title' />
			</label>
			<span data-testid='communication-title'>{communication.communicationTitle}</span>
			<label className='ms-fontWeight-bold file-details-label'>
				<FormattedMessage id='reference' defaultMessage='Reference' />
			</label>
			<span data-testid='communication-reference'>{communication.communicationProConReference}</span>
			<label className='ms-fontWeight-bold file-details-label'>
				<FormattedMessage id='templateType' defaultMessage='Template Type' />
			</label>
			<span data-testid='communication-template-title'>{communication.communicationTemplateTitle}</span>
			<label className='ms-fontWeight-bold file-details-label'>
				<FormattedMessage id='state' defaultMessage='State' />
			</label>
			<span data-testid='communication-state'>
				<FormattedMessage id={communicationState[communication.communicationState].id} />
			</span>
			<label className='ms-fontWeight-bold file-details-label'>
				<FormattedMessage id='revisionNumber' defaultMessage='Revision Number' />
			</label>
			<span data-testid='communication-revision-number'>{communication.revisionNumber}</span>
			<label className='ms-fontWeight-bold file-details-label'>
				<FormattedMessage id='createdOnDate' defaultMessage='Created On Date' />
			</label>
			<span data-testid='communication-originated-on'>
				{new Date(communication.originatedOn).toLocaleDateString()}
			</span>
		</>
	);
};
export default CommunicationDetails;
