import { useContext, useEffect, useState } from 'react';
import { endpointRefs } from '../../constants/errorTokens';
import { CustomPropertiesContext } from '../../context/CustomPropertiesContext';
import { LocalStorageTokens } from '../../enums/LocalStorageTokens';
import { determineErrorToken } from '../helpers/ErrorHelper';
import { Communication } from '../types/CommunicationDataContract';
import { i18nMessage } from '../types/i18nMessage';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function useCommunication(communicationId: number) {
	const { server } = useContext(CustomPropertiesContext);
	const [isLoadingCommunicationDetails, setIsLoadingCommunicationDetails] = useState<boolean>(true);
	const [communicationErrorToken, setCommunicationErrorToken] = useState<i18nMessage>();
	const [communication, setCommunication] = useState<Communication>();

	useEffect(() => {
		const getCommunication = async () => {
			let response: Response;

			try {
				response = await fetch(server + 'communications/' + communicationId, {
					method: 'GET',
					headers: {
						Authorization: 'Bearer ' + localStorage.getItem(LocalStorageTokens.AccessToken)
					}
				});

				if (response.ok) {
					setCommunication(await response.json());
				} else {
					setCommunicationErrorToken(determineErrorToken(endpointRefs.getCommunication, response.status));
				}
			} catch (error) {
				setCommunicationErrorToken(determineErrorToken(endpointRefs.genericErrors, 501));
			}
			setIsLoadingCommunicationDetails(false);
		};

		getCommunication();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [server, communicationId]);

	return { isLoadingCommunicationDetails, communication, communicationErrorToken };
}
