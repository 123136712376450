import { defineMessage } from '@formatjs/intl';
import { i18nMessage } from '../taskpane/types/i18nMessage';

type IEndpointRef = {
	getCommunication: IStatusCodes;
	getInternalNote: IStatusCodes;
	getFile: IStatusCodes;
	getGenericReview: IStatusCodes;
	getUser: IStatusCodes;
	noPatchFileAfterUpload: IStatusCodes;
	postFile: IStatusCodes;
	patchFileAfterUpload: IStatusCodes;
	patchFileContents: IStatusCodes;
	releaseReservation: IStatusCodes;
	genericErrors: IStatusCodes;
};

export type IStatusCodes = {
	401?: i18nMessage;
	403?: i18nMessage;
	404?: i18nMessage;
	500?: i18nMessage;
	501?: i18nMessage;
};

export const endpointRefs: IEndpointRef = {
	getCommunication: {
		403: defineMessage({
			id: 'getCommunicationForbidden',
			defaultMessage: 'You do not have permission to view this communication.'
		}),
		404: defineMessage({
			id: 'getCommunicationNotFound',
			defaultMessage: 'The communication this file belongs to no longer exists.'
		})
	},
	getInternalNote: {
		403: defineMessage({
			id: 'getInternalNoteForbidden',
			defaultMessage: 'You do not have permission to view this communication review.'
		}),
		404: defineMessage({
			id: 'getInternalNoteNotFound',
			defaultMessage: 'The communication review this file belongs to no longer exists.'
		})
	},
	getFile: {
		403: defineMessage({
			id: 'cannotViewFileDetails',
			defaultMessage: 'You do not have permission to view this file.'
		}),
		404: defineMessage({
			id: 'fileDoesNotExist',
			defaultMessage: 'This file no longer exists in AVEVA Contract Risk Management.'
		})
	},
	getGenericReview: {
		403: defineMessage({
			id: 'getGenericReviewForbidden',
			defaultMessage: 'You do not have permission to view this communication review.'
		}),
		404: defineMessage({
			id: 'getGenericReviewNotFound',
			defaultMessage: 'The communication review this file belongs to no longer exists.'
		})
	},
	getUser: {
		404: defineMessage({
			id: 'userDetailsNotFound',
			defaultMessage: 'The user profile could not be found in AVEVA Contract Risk Management.'
		})
	},
	noPatchFileAfterUpload: {
		403: defineMessage({
			id: 'fileNoReleaseAfterUploadForbidden',
			defaultMessage: 'The file was uploaded but the reservation could not be kept.'
		}),
		404: defineMessage({
			id: 'fileNoReleaseAfterUploadNotFound',
			defaultMessage: 'The file was uploaded but the reservation could not be kept.'
		})
	},
	postFile: {
		403: defineMessage({
			id: 'filePostForbidden',
			defaultMessage: 'You do not have permission to upload this file.'
		}),
		404: defineMessage({
			id: 'filePostNotFound',
			defaultMessage: 'The file cannot be uploaded because the resource it belongs to has been deleted.'
		})
	},
	patchFileAfterUpload: {
		403: defineMessage({
			id: 'fileReleaseAfterUploadForbidden',
			defaultMessage: 'The file was uploaded but the reservation could not be cleared.'
		}),
		404: defineMessage({
			id: 'fileReleaseAfterUploadNotFound',
			defaultMessage: 'The file was uploaded but the reservation could not be cleared.'
		})
	},
	patchFileContents: {
		403: defineMessage({
			id: 'filePatchContentsForbidden',
			defaultMessage: 'You do not have permission to upload this file.'
		}),
		404: defineMessage({
			id: 'filePatchContentsNotFound',
			defaultMessage: 'The file could not be uploaded because of a server issue.'
		})
	},
	releaseReservation: {
		403: defineMessage({
			id: 'releaseReservationForbidden',
			defaultMessage: 'You do not have permission to clear the reservation on this file.'
		}),
		404: defineMessage({
			id: 'releaseReservationNotFound',
			defaultMessage: 'The reservation could not be cleared because the file is out of sync.'
		})
	},
	genericErrors: {
		401: defineMessage({
			id: 'unauthorised',
			defaultMessage: 'You are not authorised to perform this action. Log in again to retry.'
		}),
		500: defineMessage({
			id: 'unknownError',
			defaultMessage: 'An unhandled error has occurred in AVEVA Contract Risk Management.'
		}),
		501: defineMessage({
			id: 'serverDown',
			defaultMessage:
				'A connection to AVEVA Contract Risk Management could not be established. Reload the add-in to retry.'
		})
	}
};
