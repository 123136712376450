import { ActionButton, ButtonType, IStackTokens, MessageBarType, Stack } from '@fluentui/react';
import React, { useCallback, useContext, useState } from 'react';
import { defineMessage, FormattedMessage } from 'react-intl';
import { CustomPropertiesContext } from '../../../context/CustomPropertiesContext';
import { FileHelper } from '../../helpers/FileHelper';
import { FilePostDTO } from '../../types/FilePostDTO';
import { FileUploadRequest } from '../../types/FileUploadRequest';
import FileUploader from '../FileUploader';
import Message from '../Message';

type props = {
	setPage: (page: string) => void;
};

const stackTokens: IStackTokens = {
	childrenGap: 0,
	padding: 'm'
};

const ReplaceExistingDocument: React.FunctionComponent<props> = ({ setPage }: props) => {
	const { server, fileId, timestamp } = useContext(CustomPropertiesContext);
	const [isFileToBeReleased, setIsFileToBeReleased] = useState(false);
	const [isUploadInitiated, setIsUploadInitiated] = useState(false);
	const [isRequestSafeToSend, setIsRequestSafeToSend] = useState(true);

	const replaceExistingDocument = useCallback(async () => {
		if (await FileHelper.isUploadRequestSafeToSend()) {
			setIsRequestSafeToSend(true);
			setIsUploadInitiated(true);
		} else {
			setIsUploadInitiated(false);
			setIsRequestSafeToSend(false);
		}
	}, []);

	const initialUploadRequestBody: FilePostDTO = {
		version: timestamp
	};

	const requestInfo: FileUploadRequest = {
		endpoint: server + 'files/' + fileId,
		uploadAndKeepReservationSuccessMessage: defineMessage({
			id: 'fileUploadReplaceExistingDocumentSuccess',
			defaultMessage:
				'The file was uploaded and the original has been replaced. This file is still reserved for editing.'
		}),
		uploadAndReleaseReservationSuccessMessage: defineMessage({
			id: 'fileUploadReleaseReplaceExistingDocumentSuccess',
			defaultMessage:
				'The file was uploaded and the original has been replaced. The reservation has been cleared.'
		})
	};

	if (isUploadInitiated) {
		return (
			<FileUploader
				isFileToBeReleased={isFileToBeReleased}
				doesEndpointAutoRelease={true}
				requestInfo={requestInfo}
				initialUploadRequestBody={initialUploadRequestBody}
				setPage={setPage}
			/>
		);
	}

	return (
		<>
			<h2 className='ms-fontWeight-light'>
				<FormattedMessage id='replaceExistingDocument' defaultMessage='Replace Existing File' />
			</h2>

			{!isRequestSafeToSend && (
				<Message
					messageBarType={MessageBarType.error}
					message={defineMessage({
						id: 'cannotUploadWhileInEditMode',
						defaultMessage: 'The file cannot be uploaded. Please ensure you are not in cell-editing mode.'
					})}
				/>
			)}

			<Stack grow verticalFill tokens={stackTokens}>
				<Stack.Item>
					<ActionButton
						className='ms-welcome__action'
						onClick={() => {
							setIsFileToBeReleased(false);
							replaceExistingDocument();
						}}
						buttonType={ButtonType.hero}
						iconProps={{ iconName: 'SaveAs' }}
					>
						<FormattedMessage id='uploadAndKeepReservation' defaultMessage='Upload and Keep Reservation' />
					</ActionButton>
				</Stack.Item>
				<Stack.Item>
					<ActionButton
						className='ms-welcome__action'
						onClick={() => {
							setIsFileToBeReleased(true);
							replaceExistingDocument();
						}}
						buttonType={ButtonType.hero}
						iconProps={{ iconName: 'Save' }}
					>
						<FormattedMessage
							id='uploadAndClearReservation'
							defaultMessage='Upload and Clear Reservation'
						/>
					</ActionButton>
				</Stack.Item>
				<Stack.Item>
					<ActionButton
						className='ms-welcome__action'
						buttonType={ButtonType.hero}
						iconProps={{ iconName: 'Cancel' }}
						onClick={() => {
							setPage('HOME');
						}}
					>
						<FormattedMessage id='cancel' defaultMessage='Cancel' />
					</ActionButton>
				</Stack.Item>
			</Stack>
		</>
	);
};

export default ReplaceExistingDocument;
