import { ActionButton, ButtonType, IconButton, IStackStyles, Panel, PanelType, Stack } from '@fluentui/react';
import React, { useContext, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useBoolean } from '@fluentui/react-hooks';
import { Utils } from '../../../utils/Utils';
import { CustomPropertiesContext } from '../../../context/CustomPropertiesContext';

const stackStyles: IStackStyles = {
	root: {
		position: 'absolute',
		bottom: 0,
		display: 'flex',
		justifyContent: 'center',
		width: '100%',
		paddingLeft: '8.5px',
		paddingTop: '20px'
	}
};

type props = {
	isValidFile: boolean;
};

const Footer: React.FunctionComponent<props> = ({ isValidFile }: props) => {
	const intl = useIntl();
	const { server } = useContext(CustomPropertiesContext);
	const [isOpen, { setTrue: openPanel, setFalse: dismissPanel }] = useBoolean(false);
	const [showAdvancedOptions, setShowAdvancedOptions] = useState(false);

	const advancedOptionsText = intl.formatMessage({
		id: 'advancedOptions',
		defaultMessage: 'Advanced Options'
	});

	const logOut = () => {
		Utils.logOut(server);
	};

	useEffect(() => {
		setShowAdvancedOptions(isValidFile);
	}, [isValidFile]);

	return (
		<>
			{showAdvancedOptions && (
				<Stack horizontalAlign='center' styles={stackStyles}>
					<Stack.Item styles={{ root: { marginRight: 10 } }}>
						<IconButton
							iconProps={{ iconName: 'ChevronUp' }}
							title={advancedOptionsText}
							ariaLabel={advancedOptionsText}
							onClick={openPanel}
						/>
						<Panel
							isOpen={isOpen}
							onDismiss={dismissPanel}
							type={PanelType.smallFluid}
							closeButtonAriaLabel={intl.formatMessage({
								id: 'close',
								defaultMessage: 'Close'
							})}
							headerText={advancedOptionsText}
						>
							<div className='ms-welcome__main'>
								<ActionButton
									className='ms-welcome__action login-button'
									buttonType={ButtonType.hero}
									iconProps={{ iconName: 'UserRemove' }}
									onClick={logOut}
								>
									<FormattedMessage
										id='terminateSession'
										defaultMessage='End session and log in as new user'
									/>
								</ActionButton>
							</div>
						</Panel>
					</Stack.Item>
				</Stack>
			)}
		</>
	);
};

export default Footer;
