import React, { useCallback, useContext } from 'react';
import { ActionButton, ButtonType, MessageBar, MessageBarType } from '@fluentui/react';
import { FormattedMessage } from 'react-intl';
import { LocalStorageTokens } from '../../../enums/LocalStorageTokens';
import { CustomPropertiesContext } from '../../../context/CustomPropertiesContext';

type props = {
	hideHeader?: boolean;
};

const Login: React.FunctionComponent<props> = ({ hideHeader }) => {
	let dialog: Office.Dialog;
	const serverName = useContext(CustomPropertiesContext).server;
	const accessTokenExpired: boolean =
		parseInt(localStorage.getItem(LocalStorageTokens.AccessTokenExpiry)) < Date.now();

	const authPopup = useCallback(async () => {
		Office.context.ui.displayDialogAsync(
			window.location.origin + '/login.html?serverName=' + serverName,
			{ height: 70, width: 40, promptBeforeOpen: false },
			// istanbul ignore next
			function (asyncResult) {
				// eslint-disable-next-line react-hooks/exhaustive-deps
				dialog = asyncResult.value;
				dialog.addEventHandler(
					Office.EventType.DialogMessageReceived,
					function (arg: { message: string; origin: string }) {
						// This will be true if the login was successful
						if (arg.message === 'true') {
							dialog.close();

							// Do a reload in order to refresh things and ensure states are synced up
							window.location.replace('/taskpane.html');
						}
					}
				);
			}
		);
	}, []);

	return (
		<div className='ms-welcome__main'>
			{!hideHeader && (
				<h2 className='ms-fontWeight-light'>
					<FormattedMessage id='loginToGetStarted' defaultMessage='Log in to get started' />
				</h2>
			)}

			{accessTokenExpired && (
				<MessageBar messageBarType={MessageBarType.error} data-testid='sessionExpired'>
					<FormattedMessage
						id='sessionExpiredPleaseLogInAgain'
						defaultMessage='Your session has expired. Log in again.'
					/>
				</MessageBar>
			)}

			<ActionButton
				className='ms-welcome__action login-button'
				buttonType={ButtonType.hero}
				iconProps={{ iconName: 'UserFollowed' }}
				onClick={authPopup}
				data-testid='loginButton'
			>
				<FormattedMessage id='login' defaultMessage='Login' />
			</ActionButton>
		</div>
	);
};

export default Login;
