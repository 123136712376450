import { createContext } from 'react';

type CustomPropertiesProvider = {
	server: string;
	fileId: number;
	timestamp: string;
	setTimestamp: (value: string) => void;
};

const customProps: CustomPropertiesProvider = { server: '', fileId: 0, timestamp: '', setTimestamp: () => ({}) };
export const CustomPropertiesContext = createContext(customProps);
