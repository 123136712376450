import { useContext, useEffect, useState } from 'react';
import { endpointRefs, IStatusCodes } from '../../constants/errorTokens';
import { CustomPropertiesContext } from '../../context/CustomPropertiesContext';
import { LocalStorageTokens } from '../../enums/LocalStorageTokens';
import { determineErrorToken } from '../helpers/ErrorHelper';
import { Communication } from '../types/CommunicationDataContract';
import { CommunicationReview, InternalNote, Review } from '../types/CommunicationReviewDetails';
import { i18nMessage } from '../types/i18nMessage';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function useCommunicationReview(parentId: number) {
	const { server } = useContext(CustomPropertiesContext);
	const [isLoadingCommunicationReviewDetails, setIsLoadingCommunicationReviewDetails] = useState<boolean>(true);
	const [communicationReviewErrorToken, setCommunicationReviewErrorToken] = useState<i18nMessage>();
	const [communicationReview, setCommunicationReview] = useState<CommunicationReview>();

	// Generic function to call a given endpoint and return the response JSON or handle the error
	const get = async (endpoint: string, endpointRef: IStatusCodes) => {
		const requestOptions: RequestInit = {
			method: 'GET',
			headers: {
				Authorization: 'Bearer ' + localStorage.getItem(LocalStorageTokens.AccessToken)
			}
		};

		let response: Response;

		try {
			response = await fetch(server + endpoint, requestOptions);

			if (!response.ok) {
				setCommunicationReviewErrorToken(determineErrorToken(endpointRef, response.status));
			}

			return response.json();
		} catch (error) {
			setCommunicationReviewErrorToken(determineErrorToken(endpointRefs.genericErrors, 501));
		}
	};

	useEffect(() => {
		const getCommunicationReview = async () => {
			// Firstly get the internal note
			const parentInternalNote: InternalNote = await get(
				'internal-notes/' + parentId,
				endpointRefs.getInternalNote
			);

			// Then the generic review
			const parentReview: Review = await get(
				'generic-reviews/' + parentInternalNote.parentProConId,
				endpointRefs.getGenericReview
			);

			// Then the communication
			const parentCommunication: Communication = await get(
				'communications/' + parentReview.communicationProConId,
				endpointRefs.getCommunication
			);

			if (communicationReviewErrorToken) {
				return;
			}

			// Use the combination of review and communication to combine the details needed for the screen
			setCommunicationReview({
				title: parentReview.title,
				communicationProConReference: parentCommunication.communicationProConReference,
				communicationTitle: parentCommunication.communicationTitle,
				assignedOnDate: parentReview.createdOn,
				dueDate: parentReview.dueDate,
				state: parentReview.state,
				decisionLabel: parentReview.decisionLabel
			});

			setIsLoadingCommunicationReviewDetails(false);
		};

		getCommunicationReview();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [server, parentId]);

	return { isLoadingCommunicationReviewDetails, communicationReview, communicationReviewErrorToken };
}
