import { ActionButton, ButtonType, IStackTokens, MessageBarType, Stack, TextField } from '@fluentui/react';
import { defineMessage } from '@formatjs/intl';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { CustomPropertiesContext } from '../../../context/CustomPropertiesContext';
import { FileParentType } from '../../../enums/FileParentType';
import { FilePostDTO } from '../../types/FilePostDTO';
import { FileUploadRequest } from '../../types/FileUploadRequest';
import FileUploader from '../FileUploader';
import Message from '../Message';
import useFile from '../../hooks/useFile';
import { FileHelper } from '../../helpers/FileHelper';

type props = {
	setPage: (page: string) => void;
};

const stackTokens: IStackTokens = {
	childrenGap: 0,
	padding: 'm'
};

const SaveAsNewDocument: React.FunctionComponent<props> = ({ setPage }: props) => {
	const intl = useIntl();
	const [fileName, setFileName] = useState<string>();
	const [isFileToBeReleased, setIsFileToBeReleased] = useState(false);
	const [isUploadInitiated, setIsUploadInitiated] = useState(false);
	const [isFileNameValid, setIsFileNameValid] = useState(false);
	const { server } = useContext(CustomPropertiesContext);
	const { file, fileErrorToken, isLoadingFileDetails } = useFile();
	const [requestInfo, setRequestInfo] = useState<FileUploadRequest>();
	const [isRequestSafeToSend, setIsRequestSafeToSend] = useState(true);

	const initialRequestInfo: FileUploadRequest = {
		endpoint: '',
		uploadAndKeepReservationSuccessMessage: defineMessage({
			id: 'fileUploadSaveAsNewDocumentSuccess',
			defaultMessage: 'The file was uploaded as a new file. The original file is still reserved for editing.'
		}),
		uploadAndReleaseReservationSuccessMessage: defineMessage({
			id: 'fileUploadReleaseSaveAsNewDocumentSuccess',
			defaultMessage:
				'The file was uploaded as a new file and the reservation on the original file has been cleared.'
		})
	};

	const initialUploadRequestBody: FilePostDTO = {
		fileName: fileName
	};

	let suffix = '';

	// Determine the file type based on the open office app
	// istanbul ignore if
	if (Office.context.host == Office.HostType.Word) {
		initialUploadRequestBody.fileExtension = 'docx';
		suffix = 'docx';
	} else {
		initialUploadRequestBody.fileExtension = 'xlsx';
		suffix = 'xlsx';
	}

	const regex = new RegExp('[:*?<>""/|\\\\]');
	const invalidFileNames = [
		'AUX',
		'COM1',
		'COM2',
		'COM3',
		'COM4',
		'COM5',
		'COM6',
		'COM7',
		'COM8',
		'COM9',
		'CON',
		'LPT1',
		'LPT2',
		'LPT3',
		'LPT4',
		'LPT5',
		'LPT6',
		'LPT7',
		'LPT8',
		'LPT9',
		'NUL',
		'PRN'
	];

	const handleFileNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setFileName(e.target.value);
	};

	const handleFileNameValidation = (value: string): string => {
		if (value.length === 0) {
			setIsFileNameValid(false);
			return intl.formatMessage({ id: 'fileNameEmpty', defaultMessage: 'The file name cannot be empty.' });
		}

		if (value.length > 145) {
			setIsFileNameValid(false);
			return intl.formatMessage({
				id: 'fileNameTooLong',
				defaultMessage: 'The file name cannot be longer than 145 characters.'
			});
		}

		if (value.match(regex)) {
			setIsFileNameValid(false);
			return intl.formatMessage({
				id: 'fileNameInvalidChar',
				defaultMessage:
					'This file name contains invalid characters. Invalid characters include * : ? < > " / | \\'
			});
		}

		if (invalidFileNames.includes(value.toUpperCase())) {
			setIsFileNameValid(false);
			return intl.formatMessage({
				id: 'fileNameWindowsReserved',
				defaultMessage: 'This file name is reserved by Windows. Please enter a different name.'
			});
		}

		setIsFileNameValid(true);
		return '';
	};

	const saveAsNewFile = useCallback(async () => {
		if (await FileHelper.isUploadRequestSafeToSend()) {
			setIsRequestSafeToSend(true);
			setIsUploadInitiated(true);
		} else {
			setIsUploadInitiated(false);
			setIsRequestSafeToSend(false);
		}
	}, []);

	useEffect(() => {
		if (isLoadingFileDetails || !file) {
			return;
		}

		// Figure out the correct endpoint to call. Only communications or internal notes are supported
		if (file.parentType === FileParentType.Communication) {
			initialRequestInfo.endpoint = server + 'communications/' + file.parentProConId + '/files';
		} else {
			initialRequestInfo.endpoint = server + 'internal-notes/' + file.parentProConId + '/files';
		}

		setRequestInfo(initialRequestInfo);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isLoadingFileDetails, file]);

	if (isUploadInitiated) {
		return (
			<FileUploader
				isFileToBeReleased={isFileToBeReleased}
				doesEndpointAutoRelease={false}
				requestInfo={requestInfo}
				initialUploadRequestBody={initialUploadRequestBody}
				setPage={setPage}
			/>
		);
	}

	if (fileErrorToken) {
		return <Message messageBarType={MessageBarType.error} message={fileErrorToken} />;
	}

	return (
		<>
			<h2 className='ms-fontWeight-light'>
				<FormattedMessage id='saveAsNewDocument' defaultMessage='Save as a New File' />
			</h2>

			{!isRequestSafeToSend && (
				<Message
					messageBarType={MessageBarType.error}
					message={defineMessage({
						id: 'cannotUploadWhileInEditMode',
						defaultMessage: 'The file cannot be uploaded. Please ensure you are not in cell-editing mode.'
					})}
				/>
			)}

			<TextField
				onGetErrorMessage={handleFileNameValidation}
				label={intl.formatMessage({ id: 'fileName', defaultMessage: 'File Name' })}
				onChange={handleFileNameChange}
				suffix={'.' + suffix}
				validateOnLoad={false}
				className='file-name'
				autoFocus
			/>
			<Stack grow verticalFill tokens={stackTokens}>
				<Stack.Item>
					<ActionButton
						className='ms-welcome__action'
						onClick={() => {
							setIsFileToBeReleased(false);
							saveAsNewFile();
						}}
						buttonType={ButtonType.hero}
						disabled={!isFileNameValid}
						iconProps={{ iconName: 'SaveAs' }}
						data-testid='upload-and-keep-button'
					>
						<FormattedMessage id='uploadAndKeepReservation' defaultMessage='Upload and Keep Reservation' />
					</ActionButton>
				</Stack.Item>
				<Stack.Item>
					<ActionButton
						className='ms-welcome__action'
						onClick={() => {
							setIsFileToBeReleased(true);
							saveAsNewFile();
						}}
						buttonType={ButtonType.hero}
						disabled={!isFileNameValid}
						iconProps={{ iconName: 'Save' }}
						data-testid='upload-and-clear-button'
					>
						<FormattedMessage
							id='uploadAndClearReservation'
							defaultMessage='Upload and Clear Reservation'
						/>
					</ActionButton>
				</Stack.Item>
				<Stack.Item>
					<ActionButton
						className='ms-welcome__action'
						buttonType={ButtonType.hero}
						iconProps={{ iconName: 'Cancel' }}
						onClick={() => {
							setPage('HOME');
						}}
					>
						<FormattedMessage id='cancel' defaultMessage='Cancel' />
					</ActionButton>
				</Stack.Item>
			</Stack>
		</>
	);
};

export default SaveAsNewDocument;
