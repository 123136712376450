import React, { useCallback, useEffect, useState } from 'react';
import Header from './pages/Header';
import Loading from './Loading';
// images references in the manifest
import '../../../assets/favicon_16x16.png';
import '../../../assets/favicon_24x24.png';
import '../../../assets/favicon_32x32.png';
import '../../../assets/favicon.png';
import '../../../assets/AVEVA_Logo_color_RGB.png';
import { useOffice } from './Office';
import { IntlProvider, defineMessage } from 'react-intl';
import defaultMessages from '../../../lang-dist/en.json';
import useCustomDocumentProperty from '../hooks/useCustomDocumentProperty';
import { CustomDocumentProperties } from '../../enums/CustomDocumentProperties';
import Home from './pages/Home';
import Login from './pages/Login';
import FileDetails from './pages/FileDetails';
import VersionedFile from './pages/VersionedFile';
import NonVersionedFile from './pages/NonVersionedFile';
import Message from './Message';
import { Utils } from '../../utils/Utils';
import { MessageBarType } from '@fluentui/react';
import SaveAsNewVersion from './pages/SaveAsNewVersion';
import SaveAsNewDocument from './pages/SaveAsNewDocument';
import ReleaseReservation from './pages/ReleaseReservation';
import ReplaceExistingDocument from './pages/ReplaceExistingDocument';
import { CustomPropertiesContext } from '../../context/CustomPropertiesContext';
import { i18nMessage } from '../types/i18nMessage';
import Footer from './pages/Footer';

const loadLocaleData = (locale: string) => {
	switch (locale) {
		case 'ru-RU':
			return import('../../../lang-dist/ru.json');
		default:
			return import('../../../lang-dist/en.json');
	}
};

const PAGES = {
	FILE_DETAILS: FileDetails,
	HOME: Home,
	LOGIN: Login,
	NON_VERSIONED_FILE: NonVersionedFile,
	VERSIONED_FILE: VersionedFile,
	SAVE_NEW_VERSION: SaveAsNewVersion,
	SAVE_NEW_DOCUMENT: SaveAsNewDocument,
	RELEASE_RESERVATION: ReleaseReservation,
	REPLACE_DOCUMENT: ReplaceExistingDocument
};

const App: React.FunctionComponent = () => {
	const office = useOffice();
	const locale: string = Office.context.displayLanguage;
	const [localeMessages, setLocaleMessages] = useState(defaultMessages);
	const [isReadingSetting, setIsReadingSetting] = useState(true);
	const [server] = useCustomDocumentProperty<string>(CustomDocumentProperties.ServerName);
	const [fileId] = useCustomDocumentProperty<number>(CustomDocumentProperties.FileId);
	const [tempTimestamp] = useCustomDocumentProperty<string>(CustomDocumentProperties.Timestamp);
	const [timestamp, setTimestamp] = useState<string>();
	const [page, setPage] = useState('HOME');
	const [isValidFile, setIsValidFile] = useState(true);
	const [errorToken, setErrorToken] = useState<i18nMessage>();

	const goBack = useCallback(() => {
		setPage('HOME');
	}, []);

	// Function made available to the CustomPropertiesContext to update the timestamp
	// after a file upload to ensure the app state is correct.
	const updateTimestamp = (value) => {
		setTimestamp(value);
	};

	const handleError = (message: i18nMessage) => {
		setErrorToken(message);
		setIsValidFile(false);
		setIsReadingSetting(false);
	};

	const determineMarkup = () => {
		if (!office.isReady || isReadingSetting) {
			return <Loading displayExcelCellEditWarning={office.host === Office.HostType.Excel} />;
		}

		if (errorToken) {
			return <Message messageBarType={MessageBarType.error} message={errorToken} />;
		}

		return <CurrentPage setPage={setPage} />;
	};

	const CurrentPage = PAGES[page];

	useEffect(() => {
		loadLocaleData(locale).then((messages) => {
			setLocaleMessages(messages);
		});
	}, [locale]);

	// Run on load
	useEffect(() => {
		if (!office.isReady) {
			return;
		}

		// Only office running on Windows is supported
		// Office online gave issues with custom doc properties and no other platforms have been tested
		if (Office.context.platform !== Office.PlatformType.PC) {
			handleError(
				defineMessage({
					id: 'platformNotSupported',
					defaultMessage:
						'This add-in is currently only supported for Microsoft Office desktop applications on Windows.'
				})
			);

			return;
		}

		if (!Utils.areRequiredApiSetsAvailable()) {
			handleError(
				defineMessage({
					id: 'apiSetsNotAvailable',
					defaultMessage: "This add-in isn't compatible with your version of Microsoft Office or Windows."
				})
			);

			return;
		}

		// The required custom OOXML properties were not found in the docs metadata
		if (server === 'ERROR' || timestamp === 'ERROR') {
			handleError(
				defineMessage({
					id: 'fileNotSupported',
					defaultMessage: 'This file is not supported because the required details were not found.'
				})
			);

			return;
		}

		// Check that the required OOXML properties have all been retrieved successfully
		if (server && fileId && tempTimestamp) {
			// Only update the timestamp if it's not already been set
			if (!timestamp) {
				setTimestamp(tempTimestamp);
			}

			setIsReadingSetting(false);
		}
	}, [office, server, fileId, tempTimestamp, timestamp]);

	return (
		<CustomPropertiesContext.Provider
			value={{ server: server, fileId: fileId, timestamp: timestamp, setTimestamp: updateTimestamp }}
		>
			<IntlProvider locale={locale} messages={localeMessages}>
				<Header goBack={goBack} page={page} isValidFile={isValidFile} />
				<div className='ms-welcome__main'>{determineMarkup()}</div>
				<Footer isValidFile={isValidFile} />
			</IntlProvider>
		</CustomPropertiesContext.Provider>
	);
};

export default App;
