import React, { createContext, useContext, useEffect, useState } from 'react';

type OfficeApp = { isReady; platform; host };

const OfficeContext = createContext<OfficeApp>({ isReady: false, platform: '', host: '' });

// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useAuth().
// eslint-disable-next-line react/prop-types
export const OfficeCanvas: React.FunctionComponent = ({ children }) => {
	const office = useOfficeApp();
	return <OfficeContext.Provider value={office}>{children}</OfficeContext.Provider>;
};

// Hook for child components to get the auth object ...
// ... and re-render when it changes.
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useOffice = () => {
	return useContext(OfficeContext);
};

// Provider hook that creates auth object and handles state
function useOfficeApp() {
	const [isReady, setIsReady] = useState<boolean>(false);
	const [platform, setPlatform] = useState({});
	const [host, setHost] = useState({});

	useEffect(() => {
		Office.onReady().then(({ host, platform }) => {
			setIsReady(true);
			setPlatform(platform);
			setHost(host);
		});
	}, []);

	return { isReady, platform, host };
}
