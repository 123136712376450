/* eslint-disable @typescript-eslint/ban-types */
import { CustomDocumentProperties } from '../../enums/CustomDocumentProperties';
import { LocalStorageTokens } from '../../enums/LocalStorageTokens';
import { Utils } from '../../utils/Utils';

// General helper class to assist with the handling and uploading of files
class FileHelper {
	// Initial post functions to create file metadata on the server
	// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
	async initialFileUpload(fileSize: number, endpoint: string, initialUploadRequestBody: object) {
		try {
			const response = await fetch(endpoint, {
				method: 'POST',
				headers: {
					'Upload-length': fileSize.toString(),
					Authorization: 'Bearer ' + localStorage.getItem(LocalStorageTokens.AccessToken),
					'Content-Type': 'application/json'
				},
				body: JSON.stringify(initialUploadRequestBody)
			});

			return response;
		} catch (error) {
			console.error('Error:', error);
			throw error;
		}
	}

	// Patch function to upload the file contents to the specified location on the server
	// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
	async patchFileContents(endpoint: string, blob: Blob) {
		try {
			const response = await fetch(endpoint, {
				method: 'PATCH',
				headers: {
					Authorization: 'Bearer ' + localStorage.getItem(LocalStorageTokens.AccessToken),
					'Content-Type': 'application/offset+octet-stream',
					'Upload-Offset': '0',
					'Content-Length': blob.size.toString()
				},
				body: blob
			});

			return response;
		} catch (error) {
			console.error('Error:', error);
			throw error;
		}
	}

	// Patch function to upload the file contents to the specified location on the server
	// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
	async refreshFileProps(server: string, fileId: number) {
		try {
			const response = await fetch(server + 'files/' + fileId, {
				method: 'GET',
				headers: {
					Authorization: 'Bearer ' + localStorage.getItem(LocalStorageTokens.AccessToken)
				}
			});

			let version: string;

			await response.json().then((json) => {
				Utils.updateCustomProperty(CustomDocumentProperties.Timestamp, json['version']);
				version = json['version'];
			});

			return version;
		} catch (error) {
			console.error('Error:', error);
			throw error;
		}
	}

	// Patch function to upload the file contents to the specified location on the server
	// Note: If reserving file it is advised to retrieve and update the timestamp ASAP
	// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
	static async toggleReservation(server: string, fileId: number, version: string, reserveFile: boolean) {
		try {
			const response = await fetch(server + 'files?reserveFile=' + reserveFile, {
				method: 'PATCH',
				headers: {
					Authorization: 'Bearer ' + localStorage.getItem(LocalStorageTokens.AccessToken)
				},
				body: JSON.stringify({ fileProConId: fileId, version: version })
			});

			return response;
		} catch (error) {
			console.error('Error:', error);
			throw error;
		}
	}

	// Validate Excel is not in cell edit mode
	static async isUploadRequestSafeToSend(): Promise<boolean> {
		if (Office.context.host === Office.HostType.Word) {
			return true;
		}

		let isSafeToSend: boolean;

		await Excel.run(async (context) => {
			try {
				// There is no method to check Excel is in cell edit mode on the office-js API, so this is a way to test it by
				// trying to get the custom properties - which is not possible when in edit mode and an exception is thrown
				context.workbook.properties.custom.getCount();
				await context.sync();
				isSafeToSend = true;
			} catch {
				isSafeToSend = false;
			}
		});

		return isSafeToSend;
	}

	// Retrieve file contents using the Office API
	getFile(): Promise<Office.File> {
		return new Promise((resolve, reject) => {
			Office.context.document.getFileAsync(Office.FileType.Compressed, function (result) {
				if (result.status == Office.AsyncResultStatus.Succeeded) {
					resolve(result.value);
				} else {
					reject(result.error);
				}
			});
		});
	}

	// Get the next slice of the file contents
	getSlice(file: Office.File, sliceIndex: number): Promise<Office.Slice> {
		return new Promise((resolve, reject) => {
			file.getSliceAsync(sliceIndex, (result) => {
				if (result.status == Office.AsyncResultStatus.Succeeded) {
					resolve(result.value);
				} else {
					reject(result.error);
				}
			});
		});
	}

	// Close async stream of the file contents
	closeFile(file: Office.File): Promise<void> {
		return new Promise((resolve, reject) => {
			file.closeAsync((result) => {
				if (result.status == Office.AsyncResultStatus.Succeeded) {
					resolve();
				} else {
					reject(result.error);
				}
			});
		});
	}
}

export { FileHelper };
