import { useContext, useEffect, useState } from 'react';
import { endpointRefs } from '../../constants/errorTokens';
import { CustomPropertiesContext } from '../../context/CustomPropertiesContext';
import { LocalStorageTokens } from '../../enums/LocalStorageTokens';
import { determineErrorToken } from '../helpers/ErrorHelper';
import { CRMFile } from '../types/CRMFile';
import { i18nMessage } from '../types/i18nMessage';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function useFile() {
	const { fileId, server, timestamp } = useContext(CustomPropertiesContext);
	const [isLoadingFileDetails, setIsLoadingFileDetails] = useState<boolean>(true);
	const [fileErrorToken, setFileErrorToken] = useState<i18nMessage>();
	const [file, setFile] = useState<CRMFile>();

	useEffect(() => {
		const getFile = async () => {
			let response: Response;

			try {
				response = await fetch(server + 'files/' + fileId, {
					method: 'GET',
					headers: {
						Authorization: 'Bearer ' + localStorage.getItem(LocalStorageTokens.AccessToken)
					}
				});

				if (response.ok) {
					setFile(await response.json());
				} else {
					setFileErrorToken(determineErrorToken(endpointRefs.getFile, response.status));
				}
			} catch (error) {
				setFileErrorToken(determineErrorToken(endpointRefs.genericErrors, 501));
			}
			setIsLoadingFileDetails(false);
		};

		getFile();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [timestamp, server]);

	return { isLoadingFileDetails, file, fileErrorToken };
}
