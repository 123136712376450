import {
	ActionButton,
	ButtonType,
	DefaultButton,
	Dialog,
	DialogType,
	DialogFooter,
	PrimaryButton,
	Persona,
	IPersonaSharedProps,
	MessageBarType,
	PersonaSize
} from '@fluentui/react';
import React, { useContext, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useBoolean } from '@fluentui/react-hooks';
import { Utils } from '../../../utils/Utils';
import Loading from '../Loading';
import Message from '../Message';
import { defineMessage } from '@formatjs/intl';
import { CustomPropertiesContext } from '../../../context/CustomPropertiesContext';
import useUser from '../../hooks/useUser';

const modalPropsStyles = { main: { maxWidth: 450 } };

// eslint-disable-next-line react/prop-types
const UserProfile: React.FunctionComponent = () => {
	const [persona, setPersona] = useState<IPersonaSharedProps>();
	const { server } = useContext(CustomPropertiesContext);
	const intl = useIntl();
	const { user, userErrorToken, isLoadingUserDetails } = useUser();

	const dialogContentProps = {
		type: DialogType.normal,
		title: intl.formatMessage({ id: 'logOut', defaultMessage: 'Log Out' }),
		subText: intl.formatMessage({ id: 'confirmLogOut', defaultMessage: 'Are you sure you want to log out?' })
	};

	const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true);
	const modalProps = React.useMemo(
		() => ({
			isBlocking: true,
			styles: modalPropsStyles
		}),
		[]
	);

	useEffect(() => {
		if (isLoadingUserDetails || !user) {
			return;
		}

		const persona: IPersonaSharedProps = {
			imageInitials: user.firstName.charAt(0) + user.lastName.charAt(0),
			text: user.firstName + ' ' + user.lastName,
			secondaryText: user.email
		};

		setPersona(persona);
	}, [isLoadingUserDetails, user]);

	// Logs the user out of the add-in
	const logOut = () => {
		Utils.logOut(server);
	};

	let userDetailsMarkup: JSX.Element;

	if (isLoadingUserDetails) {
		userDetailsMarkup = (
			<Loading
				label={defineMessage({ id: 'retrievingUserDetails', defaultMessage: 'Retrieving user details' })}
			/>
		);
	} else if (userErrorToken) {
		userDetailsMarkup = <Message messageBarType={MessageBarType.error} message={userErrorToken} />;
	} else {
		userDetailsMarkup = <Persona {...persona} size={PersonaSize.size40} />;
	}

	return (
		<div className='ms-welcome__main'>
			<br />
			{userDetailsMarkup}
			{!userErrorToken && (
				<ActionButton
					className='ms-welcome__action login-button'
					buttonType={ButtonType.hero}
					iconProps={{ iconName: 'UserRemove' }}
					onClick={toggleHideDialog}
				>
					<FormattedMessage id='logOut' defaultMessage='Log Out' />
				</ActionButton>
			)}

			<Dialog
				hidden={hideDialog}
				onDismiss={toggleHideDialog}
				dialogContentProps={dialogContentProps}
				modalProps={modalProps}
			>
				<DialogFooter>
					<PrimaryButton onClick={logOut}>
						<FormattedMessage id='ok' defaultMessage='OK' />
					</PrimaryButton>
					<DefaultButton onClick={toggleHideDialog}>
						<FormattedMessage id='cancel' defaultMessage='Cancel' />
					</DefaultButton>
				</DialogFooter>
			</Dialog>
		</div>
	);
};

export default UserProfile;
