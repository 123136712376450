import { defineMessage } from '@formatjs/intl';
import { i18nMessage } from '../taskpane/types/i18nMessage';

export const communicationState: { [key: string]: i18nMessage } = {
	Draft: defineMessage({ id: 'draft', defaultMessage: 'Draft' }),
	Approved: defineMessage({ id: 'approved', defaultMessage: 'Approved' }),
	Rejected: defineMessage({ id: 'rejected', defaultMessage: 'Rejected' }),
	AwaitingAgreementFromRecipient: defineMessage({
		id: 'awaitingAgreementFromRecipient',
		defaultMessage: 'Awaiting Agreement from Recipient'
	}),
	Agreed: defineMessage({ id: 'agreed', defaultMessage: 'Agreed' }),
	NotAgreed: defineMessage({ id: 'notAgreed', defaultMessage: 'Not Agreed' })
};

export const reviewState: { [key: string]: i18nMessage } = {
	InProgress: defineMessage({ id: 'inProgress', defaultMessage: 'In Progress' }),
	Cancelled: defineMessage({ id: 'cancelled', defaultMessage: 'Cancelled' }),
	Completed: defineMessage({ id: 'completed', defaultMessage: 'Completed' })
};
