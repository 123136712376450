import { endpointRefs, IStatusCodes } from '../../constants/errorTokens';
import { i18nMessage } from '../types/i18nMessage';

// Determines an appropriate error message based on the status code retreived from the response
// and the supplied endpoint reference
export function determineErrorToken(endpointRef: IStatusCodes, statusCode: number): i18nMessage {
	switch (statusCode) {
		case 401:
			return endpointRefs.genericErrors[401];
		case 403:
		case 404:
			return endpointRef[statusCode];
		case 500:
			return endpointRefs.genericErrors[500];
		default:
			return endpointRefs.genericErrors[501];
	}
}
