import { useContext, useEffect, useState } from 'react';
import { endpointRefs } from '../../constants/errorTokens';
import { CustomPropertiesContext } from '../../context/CustomPropertiesContext';
import { LocalStorageTokens } from '../../enums/LocalStorageTokens';
import { determineErrorToken } from '../helpers/ErrorHelper';
import { CRMUser } from '../types/CRMUser';
import { i18nMessage } from '../types/i18nMessage';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function useUser() {
	const { server } = useContext(CustomPropertiesContext);
	const [isLoadingUserDetails, setIsLoadingUserDetails] = useState<boolean>(true);
	const [userErrorToken, setUserErrorToken] = useState<i18nMessage>();
	const [user, setUser] = useState<CRMUser>();

	useEffect(() => {
		const getUser = async () => {
			if (!server) {
				return;
			}

			try {
				const response: Response = await fetch(server + 'executing-user/user-details', {
					method: 'GET',
					headers: {
						Authorization: 'Bearer ' + localStorage.getItem(LocalStorageTokens.AccessToken)
					}
				});

				if (response.ok) {
					setUser(await response.json());
				} else {
					setUserErrorToken(determineErrorToken(endpointRefs.getUser, response.status));
				}
			} catch (error) {
				setUserErrorToken(determineErrorToken(endpointRefs.genericErrors, 501));
			}

			setIsLoadingUserDetails(false);
		};

		getUser();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [server]);

	return { isLoadingUserDetails, user, userErrorToken };
}
