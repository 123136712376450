import { MessageBarType } from '@fluentui/react';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import Loading from '../Loading';
import Message from '../Message';
import { FileParentType } from '../../../enums/FileParentType';
import CommunicationDetails from './CommunicationDetails';
import ReviewDetails from './ReviewDetails';
import useFile from '../../hooks/useFile';

const FileDetails: React.FunctionComponent = () => {
	const { isLoadingFileDetails, file, fileErrorToken } = useFile();

	if (isLoadingFileDetails) {
		return <Loading />;
	}

	if (fileErrorToken) {
		return <Message messageBarType={MessageBarType.error} message={fileErrorToken} />;
	}

	return (
		<div className='file-details-container'>
			<>
				<h2 className='ms-fontWeight-light file-details-h2'>
					<FormattedMessage id='fileDetails' defaultMessage='File Details' />
				</h2>
				<label className='ms-fontWeight-bold file-details-label'>
					<FormattedMessage id='versionTrackingEnabled' defaultMessage='Version Tracking Enabled' />
				</label>
				<span data-testid='version-tracking-enabled'>
					{file.isVersionTrackingEnabled ? (
						<FormattedMessage id='yes' defaultMessage='Yes' />
					) : (
						<FormattedMessage id='no' defaultMessage='No' />
					)}
				</span>
				<label className='ms-fontWeight-bold file-details-label'>
					<FormattedMessage id='versionNumber' defaultMessage='Version Number' />
				</label>
				<span data-testid='version-number'>
					{file.fileVersionNumber > 0 ? (
						file.fileVersionNumber
					) : (
						<FormattedMessage id='notApplicable' defaultMessage='Not applicable' />
					)}
				</span>
				<label className='ms-fontWeight-bold file-details-label'>
					<FormattedMessage id='confidential' defaultMessage='Confidential' />
				</label>
				<span data-testid='confidential'>
					{file.isConfidential ? (
						<FormattedMessage id='yes' defaultMessage='Yes' />
					) : (
						<FormattedMessage id='no' defaultMessage='No' />
					)}
				</span>
				<label className='ms-fontWeight-bold file-details-label'>
					<FormattedMessage id='visibleInContractorPortal' defaultMessage='Visible in Contractor Portal' />
				</label>
				<span data-testid='visible-in-contractor-portal'>
					{file.isVisibleInContractorPortal ? (
						<FormattedMessage id='yes' defaultMessage='Yes' />
					) : (
						<FormattedMessage id='no' defaultMessage='No' />
					)}
				</span>
			</>
			{file.parentType == FileParentType.GenericReview && !isLoadingFileDetails && (
				<ReviewDetails fileParentId={file.parentProConId} />
			)}
			{file.parentType == FileParentType.Communication && !isLoadingFileDetails && (
				<CommunicationDetails fileParentId={file.parentProConId} />
			)}
		</div>
	);
};

export default FileDetails;
