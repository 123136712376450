import { ActionButton, ButtonType, IStackTokens, MessageBarType, Stack, TextField } from '@fluentui/react';
import { defineMessage } from '@formatjs/intl';
import React, { useCallback, useContext, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { CustomPropertiesContext } from '../../../context/CustomPropertiesContext';
import { FileHelper } from '../../helpers/FileHelper';
import { FilePostDTO } from '../../types/FilePostDTO';
import { FileUploadRequest } from '../../types/FileUploadRequest';
import FileUploader from '../FileUploader';
import Message from '../Message';

type props = {
	setPage: (page: string) => void;
};

const stackTokens: IStackTokens = {
	childrenGap: 0,
	padding: 'm'
};

const SaveAsNewVersion: React.FunctionComponent<props> = ({ setPage }: props) => {
	const { server, fileId, timestamp } = useContext(CustomPropertiesContext);
	const [versionNote, setVersionNote] = useState<string>();
	const [isFileToBeReleased, setIsFileToBeReleased] = useState(false);
	const [isUploadInitiated, setIsUploadInitiated] = useState(false);
	const [isFileVersionNoteValid, setIsFileVersionNoteValid] = useState(false);
	const [isRequestSafeToSend, setIsRequestSafeToSend] = useState(true);
	const intl = useIntl();

	const initialUploadRequestBody: FilePostDTO = {
		fileVersionNote: versionNote,
		version: timestamp
	};

	const handleVersionNoteChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setVersionNote(e.target.value);
	};

	const handleVersionNoteValidation = (value: string): string => {
		if (value.length > 255) {
			setIsFileVersionNoteValid(false);

			return intl.formatMessage({
				id: 'fileReasonForChangeTooLong',
				defaultMessage: 'The reason for change cannot be longer than 255 characters.'
			});
		}

		setIsFileVersionNoteValid(true);
		return '';
	};

	const saveAsNewVersion = useCallback(async () => {
		if (await FileHelper.isUploadRequestSafeToSend()) {
			setIsRequestSafeToSend(true);
			setIsUploadInitiated(true);
		} else {
			setIsUploadInitiated(false);
			setIsRequestSafeToSend(false);
		}
	}, []);

	const requestInfo: FileUploadRequest = {
		endpoint: server + 'files/' + fileId + '/versions',
		uploadAndKeepReservationSuccessMessage: defineMessage({
			id: 'fileUploadSaveAsNewVersionSuccess',
			defaultMessage: 'The file was uploaded as a new version. This file is still reserved for editing.'
		}),
		uploadAndReleaseReservationSuccessMessage: defineMessage({
			id: 'fileUploadReleaseSaveAsNewVersionSuccess',
			defaultMessage: 'The file was uploaded as a new version and the reservation has been cleared.'
		})
	};

	if (isUploadInitiated) {
		return (
			<FileUploader
				isFileToBeReleased={isFileToBeReleased}
				doesEndpointAutoRelease={true}
				requestInfo={requestInfo}
				initialUploadRequestBody={initialUploadRequestBody}
				setPage={setPage}
			/>
		);
	}

	return (
		<>
			<h2 className='ms-fontWeight-light'>
				<FormattedMessage id='saveAsNewVersion' defaultMessage='Replace File with New Version' />
			</h2>

			{!isRequestSafeToSend && (
				<Message
					messageBarType={MessageBarType.error}
					message={defineMessage({
						id: 'cannotUploadWhileInEditMode',
						defaultMessage: 'The file cannot be uploaded. Please ensure you are not in cell-editing mode.'
					})}
				/>
			)}

			<TextField
				onGetErrorMessage={handleVersionNoteValidation}
				label={intl.formatMessage({ id: 'reasonForChange', defaultMessage: 'Reason for Change' })}
				multiline
				rows={3}
				onChange={handleVersionNoteChange}
				className='versioning-note'
				autoFocus
			/>
			<Stack grow verticalFill tokens={stackTokens}>
				<Stack.Item>
					<ActionButton
						className='ms-welcome__action'
						disabled={!isFileVersionNoteValid}
						onClick={() => {
							setIsFileToBeReleased(false);
							saveAsNewVersion();
						}}
						buttonType={ButtonType.hero}
						iconProps={{ iconName: 'SaveAs' }}
						data-testid='upload-and-keep-button'
					>
						<FormattedMessage id='uploadAndKeepReservation' defaultMessage='Upload and Keep Reservation' />
					</ActionButton>
				</Stack.Item>
				<Stack.Item>
					<ActionButton
						className='ms-welcome__action'
						disabled={!isFileVersionNoteValid}
						onClick={() => {
							setIsFileToBeReleased(true);
							saveAsNewVersion();
						}}
						buttonType={ButtonType.hero}
						iconProps={{ iconName: 'Save' }}
						data-testid='upload-and-clear-button'
					>
						<FormattedMessage
							id='uploadAndClearReservation'
							defaultMessage='Upload and Clear Reservation'
						/>
					</ActionButton>
				</Stack.Item>
				<Stack.Item>
					<ActionButton
						className='ms-welcome__action'
						buttonType={ButtonType.hero}
						iconProps={{ iconName: 'Cancel' }}
						onClick={() => {
							setPage('HOME');
						}}
					>
						<FormattedMessage id='cancel' defaultMessage='Cancel' />
					</ActionButton>
				</Stack.Item>
			</Stack>
		</>
	);
};

export default SaveAsNewVersion;
