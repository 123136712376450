import { ActionButton, ButtonType, IStackTokens, MessageBarType, Stack } from '@fluentui/react';
import React, { useCallback, useContext, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import Loading from '../Loading';
import Message from '../Message';
import { endpointRefs } from '../../../constants/errorTokens';
import { determineErrorToken } from '../../helpers/ErrorHelper';
import { FileHelper } from '../../helpers/FileHelper';
import { i18nMessage } from '../../types/i18nMessage';
import { defineMessage } from '@formatjs/intl';
import { CustomPropertiesContext } from '../../../context/CustomPropertiesContext';

type props = {
	setPage: (page: string) => void;
};
const stackTokens: IStackTokens = {
	childrenGap: 0,
	padding: 'm'
};

const ReleaseReservation: React.FunctionComponent<props> = ({ setPage }: props) => {
	const { server, fileId, timestamp } = useContext(CustomPropertiesContext);
	const [isSending, setIsSending] = useState(false);
	const [errorToken, setErrorToken] = useState<i18nMessage>();
	const [isReservationCleared, setIsReservationCleared] = useState(false);

	const removeReservation = useCallback(async () => {
		// istanbul ignore if
		if (isSending) {
			return;
		}

		setIsSending(true);
		const releaseResponse = await FileHelper.toggleReservation(server, fileId, timestamp, false);

		if (releaseResponse.ok) {
			setIsReservationCleared(true);
		} else {
			setErrorToken(determineErrorToken(endpointRefs.releaseReservation, releaseResponse.status));
		}

		setIsSending(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isSending]);

	if (isSending) {
		return <Loading />;
	}

	if (errorToken) {
		return <Message messageBarType={MessageBarType.error} message={errorToken} />;
	}

	if (isReservationCleared) {
		return (
			<Message
				messageBarType={MessageBarType.success}
				message={defineMessage({
					id: 'reservationCleared',
					defaultMessage: 'The file is no longer reserved for editing.'
				})}
			/>
		);
	}

	return (
		<>
			<h2 className='ms-fontWeight-light'>
				<FormattedMessage id='clearReservation' defaultMessage='Clear Reservation' />
			</h2>
			<Stack grow verticalFill tokens={stackTokens}>
				<Stack.Item>
					<ActionButton
						className='ms-welcome__action'
						buttonType={ButtonType.hero}
						iconProps={{ iconName: 'Unlock' }}
						onClick={removeReservation}
					>
						<FormattedMessage id='confirm' defaultMessage='Confirm' />
					</ActionButton>
				</Stack.Item>
				<Stack.Item>
					<ActionButton
						className='ms-welcome__action'
						buttonType={ButtonType.hero}
						iconProps={{ iconName: 'Cancel' }}
						onClick={() => {
							setPage('HOME');
						}}
					>
						<FormattedMessage id='cancel' defaultMessage='Cancel' />
					</ActionButton>
				</Stack.Item>
			</Stack>
		</>
	);
};

export default ReleaseReservation;
