import { ActionButton, ButtonType, MessageBarType } from '@fluentui/react';
import { defineMessage } from '@formatjs/intl';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { endpointRefs } from '../../constants/errorTokens';
import { CustomPropertiesContext } from '../../context/CustomPropertiesContext';
import { determineErrorToken } from '../helpers/ErrorHelper';
import { FileHelper } from '../helpers/FileHelper';
import { FilePostDTO } from '../types/FilePostDTO';
import { FileUploadRequest } from '../types/FileUploadRequest';
import { i18nMessage } from '../types/i18nMessage';
import Loading from './Loading';
import Message from './Message';
import useFile from '../hooks/useFile';

type props = {
	isFileToBeReleased: boolean;
	doesEndpointAutoRelease: boolean;
	requestInfo: FileUploadRequest;
	initialUploadRequestBody: FilePostDTO;
	setPage: (page: string) => void;
};

const FileUploader: React.FunctionComponent<props> = ({
	isFileToBeReleased,
	doesEndpointAutoRelease,
	requestInfo,
	initialUploadRequestBody,
	setPage
}: props) => {
	const [requestSent, setRequestSent] = useState(false);
	const { server, fileId, timestamp, setTimestamp } = useContext(CustomPropertiesContext);
	const [errorToken, setErrorToken] = useState<i18nMessage>();
	const [successToken, setSuccessToken] = useState<i18nMessage>();
	const [includeOkButton, setIncludeOkButton] = useState(false);

	const { file, isLoadingFileDetails, fileErrorToken } = useFile();

	const determineMarkup = () => {
		if (errorToken || fileErrorToken) {
			return <Message messageBarType={MessageBarType.error} message={errorToken ? errorToken : fileErrorToken} />;
		}

		if (successToken) {
			return <Message messageBarType={MessageBarType.success} message={successToken} />;
		}

		return <Loading label={defineMessage({ id: 'uploading', defaultMessage: 'Uploading' })} />;
	};

	const sendFile = useCallback(async () => {
		setRequestSent(true);

		initialUploadRequestBody.fileName = initialUploadRequestBody.fileName ?? file.fileName;
		initialUploadRequestBody.isConfidential = file.isConfidential;
		initialUploadRequestBody.isVisibleInContractorPortal = file.isVisibleInContractorPortal;

		const fileHelper: FileHelper = new FileHelper();

		// Retrieve the file contents from the Office App
		const officeFile: Office.File = await fileHelper.getFile();

		// Post the initial file creation with the relevant data
		const initialResponse = await fileHelper.initialFileUpload(
			officeFile.size,
			requestInfo.endpoint,
			initialUploadRequestBody
		);

		// The initial post to create the placeholder was successful
		if (initialResponse.status === 201) {
			const fileBytes = new Uint8Array(officeFile.size);

			for (let sliceIndex = 0; sliceIndex < officeFile.sliceCount; sliceIndex++) {
				const slice = await fileHelper.getSlice(officeFile, sliceIndex);
				fileBytes.set(slice.data, sliceIndex);
			}

			const blob: Blob = new Blob([fileBytes]);

			// Upload the contents of our file to the specified location on the server
			const patchContentsResponse: Response = await fileHelper.patchFileContents(
				initialResponse.headers.get('location'),
				blob
			);

			if (patchContentsResponse.ok) {
				let reservationProcessSuccessful = false;

				// Determine required process dependent on whether the file reservation is to be released
				// and if the used endpoint has automatically released that reservation
				if (isFileToBeReleased) {
					if (doesEndpointAutoRelease) {
						setSuccessToken(requestInfo.uploadAndReleaseReservationSuccessMessage);
						reservationProcessSuccessful = true;
					} else {
						const toggleReservationResponse: Response = await FileHelper.toggleReservation(
							server,
							fileId,
							timestamp,
							false
						);

						if (toggleReservationResponse.ok) {
							setSuccessToken(requestInfo.uploadAndReleaseReservationSuccessMessage);
							reservationProcessSuccessful = true;
						} else {
							setErrorToken(
								determineErrorToken(endpointRefs.patchFileAfterUpload, patchContentsResponse.status)
							);
						}
					}
				} else {
					if (doesEndpointAutoRelease) {
						const refreshedTimestamp: string = await fileHelper.refreshFileProps(server, fileId);
						const toggleReservationResponse: Response = await FileHelper.toggleReservation(
							server,
							fileId,
							refreshedTimestamp,
							true
						);

						const timestampAfterReservation: string = await fileHelper.refreshFileProps(server, fileId);
						setTimestamp(timestampAfterReservation);

						if (toggleReservationResponse.ok) {
							setSuccessToken(requestInfo.uploadAndKeepReservationSuccessMessage);
							reservationProcessSuccessful = true;
						} else {
							setErrorToken(
								determineErrorToken(endpointRefs.noPatchFileAfterUpload, patchContentsResponse.status)
							);
						}
					} else {
						setSuccessToken(requestInfo.uploadAndKeepReservationSuccessMessage);
						reservationProcessSuccessful = true;
					}

					if (reservationProcessSuccessful) {
						setIncludeOkButton(true);
					}
				}
			} else {
				setErrorToken(determineErrorToken(endpointRefs.patchFileContents, patchContentsResponse.status));
			}
		} else {
			setErrorToken(determineErrorToken(endpointRefs.postFile, initialResponse.status));
		}

		await fileHelper.closeFile(officeFile);
	}, [
		doesEndpointAutoRelease,
		file,
		fileId,
		initialUploadRequestBody,
		isFileToBeReleased,
		requestInfo,
		server,
		setTimestamp,
		timestamp
	]);

	// Function that runs when the component first loads
	useEffect(() => {
		if (isLoadingFileDetails || fileErrorToken) {
			return;
		}

		if (!requestSent) {
			sendFile();
		}
	}, [requestSent, isLoadingFileDetails, fileErrorToken, sendFile]);

	return (
		<>
			{determineMarkup()}
			{includeOkButton && (
				<>
					<br />
					<ActionButton
						className='ms-welcome__action'
						onClick={() => {
							setPage('HOME');
						}}
						buttonType={ButtonType.hero}
						iconProps={{ iconName: 'Accept' }}
					>
						<FormattedMessage id='ok' defaultMessage='OK' />
					</ActionButton>
				</>
			)}
		</>
	);
};

export default FileUploader;
